(function () {
	const loader = {};
	const loaderGlobalName = 'broadcastLoader';
	const currentScriptSrc = document.currentScript ? document.currentScript.getAttribute("src") : '';

	if (undefined !== window[loaderGlobalName]) {
		console.warn('Multiple embed script implemented');
		return;
	}

	window['comdiLoader'] = window[loaderGlobalName] = loader;

	const frames = {};
	const listeners = {};
	loader.on = (eventName, handler, context) => {
		if (!listeners[eventName]) {
			listeners[eventName] = [];
		}
		listeners[eventName].push({ eventName, handler, context });
		return loader;
	};
	loader.trigger = (eventName, args, context) => {
		if (!(args instanceof Array)) {
			args = undefined !== args ? [args] : [];
		}
		if (listeners[eventName]) {
			listeners[eventName].forEach(listener => {
				try {
					listener.handler.apply(listener.context || context || loader, args);
				} catch (e) {
					console.error('trigger:' + eventName, e);
				}
			});
		}
		return loader;
	};
	loader.sendMessage = function (watchKey, eventName, data) {
		if (!frames.hasOwnProperty(watchKey)) {
			console.warn('Player not found by key', watchKey);

			return;
		}

		frames[watchKey].contentWindow.postMessage(JSON.stringify({ [eventName]: data }), '*');
	};

	loader.on('setHeight', function (height) {
		this.style.height = height + 'px';
		this.style.minWidth = '100%';
		this.style.width = '100px';
	});
	loader.on('scrollToFrame', function (offset) {
		document.body.scrollTop = this.offsetTop + offset;
	});
	loader.on('embedCheck', function () {
		this.contentWindow.postMessage(JSON.stringify({ embedConfirm: true }), '*');
	});

	const onMessage = event => {
		const frame = (() => {
			const frames = document.getElementsByTagName('iframe');
			for (let i = 0; i < frames.length; i++) {
				if (frames[i].contentWindow === event.source) {
					return frames[i];
				}
			}
		})();

		if (!frame) return;

		try {
			const data = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
			Object.keys(data).forEach(key => loader.trigger(key, data[key], frame));
		} catch (e) {
			console.warn('Unknown postMessage', event.data);
		}
	};

	const getParameters = (container) => {
		let params = {};
		let registrationForm = {};

		for (let i = 0; i < container.attributes.length; i++) {
			let attributeName = container.attributes[i].name;
			let attributeValue = container.attributes[i].value;

			if (attributeName.toLowerCase() === 'data-eventKey'.toLowerCase()
				|| attributeName.match(/^data-\w+-event$/)
			) {
				params['eventKey'] = attributeValue;

				continue;
			}

			if (attributeName === 'data-style') {
				attributeValue.split(';')
					.forEach(style => {
						let [key, value] = style.split(':');
						if (undefined !== value) {
							params[key.trim()] = value.trim().replace(/^#([\da-f]{3,6})/gi, '$1');
						}
					});

				continue;
			}

			let matchColor = attributeName.match(/^data-(.*)-color$/i);
			if (matchColor) {
				let key = matchColor[1] + 'Color';
				params[key] = container.attributes[i].value.replace(/^#([\da-f]{3,6})/gi, '$1');

				continue;
			}

			let matchRegistrationField = attributeName.match(/^data-registration-(.+)$/);
			if (matchRegistrationField) {
				registrationForm[matchRegistrationField[1]] = attributeValue;
			}

			if (0 === attributeName.indexOf('data-utm-') || 0 === attributeName.indexOf('data-utm_')) {
				params[attributeName.substring(5).replace('utm-', 'utm_')] = attributeValue;
			}
			if (attributeName === 'data-compact') {
				params.compactPlayer = attributeValue;
			}
			if (attributeName === 'data-footer') {
				params.hideFooter = (attributeValue === 'false').toString();
			}
			if (attributeName === 'data-autoplay') {
				params.autoplay = attributeValue;
			}
			if (attributeName === 'lang') {
				params.lang = attributeValue;
			}
			if (attributeName === 'data-login') {
				params.name = attributeValue;
			}
			if (attributeName === 'data-registration') {
				Object.assign(registrationForm, JSON.parse(attributeValue));
			}
			if (attributeName === 'data-no-reg') {
				params.noReg = true;
			}
		}

		if (Object.keys(registrationForm).length > 0) {
			params.registration_form = JSON.stringify(registrationForm);
		}

		return params;
	};

	const checkLocalhost = (param) => {
		return !(/^https?:\/\/localhost/i).test(String(param))
	}

	const object2query = params =>
		Object.keys(params)
			.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
			.join('&');

	const getEmbedLocation = () => {
		const params = {};

		if (checkLocalhost(document.location)) {
			params.embedLocation = String(document.location);
		}

		return params;
	}

	const getParamsFromHostQuery = () => {
		const params = {};

		(new URLSearchParams(window.location.search)).forEach((value, key) => {
			if (0 === key.indexOf('utm_')) {
				params[key] = value;
			}
			if ('accessToken' === key) {
				params[key] = value;
			}
		});

		return params;
	};

	const getReferrerParams = () => {
		const params = {};

		if (document.referrer && checkLocalhost(document.referrer)) {
			params.referrer = document.referrer;
		}

		return params;
	};

	const createPlayer = container => {
		let playerHost = (container.src || container.getAttribute('src') || currentScriptSrc || '')
			.replace(/^(https?:\/\/[^\/]+)\/.*/, '$1');
		let queryParameters = Object.assign({},
			getEmbedLocation(),
			getParamsFromHostQuery(),
			getReferrerParams(),
			getParameters(container)
		);
		const watchKey = queryParameters['eventKey'];
		delete queryParameters['eventKey'];

		// if (!playerHost && location.origin.replace('www.', '').match(/\.comdi\.com$/)) {
		// 	playerHost = location.origin;
		// }
		//
		if (!playerHost) {
			playerHost = (process.env.SCHEME || 'https') + '://' + (process.env.HOST || 'broadcast.comdi.com');
		}

		const frame = document.createElement('iframe');
		frame.src = `${playerHost}/player/${watchKey}?${object2query(queryParameters)}#show`;
		frame.setAttribute('width', container.getAttribute('data-width') || '100%');
		frame.setAttribute('height', container.getAttribute('data-height') || '600');
		frame.setAttribute('frameBorder', '0');
		frame.setAttribute('scrolling', 'no');
		frame.setAttribute('allowFullScreen', 'true');
		frame.setAttribute('allow', 'autoplay; fullscreen');
		frame.setAttribute('data-eventKey', watchKey);
		container.parentNode.replaceChild(frame, container);
		frames[watchKey] = frame;
		console.log('Create Broadcast Player', watchKey, queryParameters);

		return frame;
	};

	loader.createPlayer = createPlayer;

	if ('addEventListener' in window) {
		window.addEventListener('message', onMessage, false);
	} else if ('attachEvent' in window) {
		window.attachEvent('onmessage', onMessage);
	} else {
		window.onmessage = onMessage;
	}

	setTimeout(() => {
		console.log('Init Broadcast Player');
		const container = document.querySelector('[data-eventKey],[data-comdi-event]');
		if (!container || container.nodeName === 'IFRAME') {
			console.log('Broadcast Player not found');

			return;
		}

		createPlayer(container);
	}, 0);
})();
